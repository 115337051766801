import React, { Component } from 'react';
import { graphql, navigate } from 'gatsby';

import { styled } from 'utils/emotion';
import media from 'utils/mediaqueries';

import Container from 'components/Container';
import Headline from 'components/Headline';
import { bgGradientBlueOverlay50 } from 'components/Gradients';
import ButtonBack from 'components/ButtonBack';

const HeroSection = styled('section')(
  {
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    maxHeight: 250,
    height: '35vh',
    paddingTop: 20,
    paddingBottom: 20,
    zIndex: '0',

    [media(640)]: {
      minHeight: 340,
      height: '45vh',
    },

    [media('sm')]: {
      minHeight: 510,
      height: '50vh',
    },
  },
  props => ({
    backgroundImage: props.backgroundImage,
  }),
);

const ButtonBackStyled = styled(ButtonBack)({
  position: 'absolute',
  transform: 'translateY(-50%)',
  top: '60%',
  left: 18,

  [media('xs')]: {
    left: 25,
  },

  [media('sm')]: {
    top: 'auto',
    bottom: 50,
  },

  [media('lg')]: {
    bottom: 50,
    left: 'auto',
    marginLeft: 5,
  },
});

const HeroContent = styled('div')({
  position: 'absolute',
  textAlign: 'center',
  color: 'white',
  padding: '0 50px',
  width: '100%',
  left: '50%',
  top: '60%',
  transform: 'translate(-50%, -50%)',

  [media('xs')]: {
    padding: '0 65px',
  },

  [media('sm')]: {
    width: '80%',
  },
});

/**
 * Navigate back behavior:
 * from news overview (e.g.: "/aktuelles"): go back to home
 * from news page (e.g.: "/aktuelles/news-page"): go back to where you come from
 */
function navigateBackFrom(currentLocation) {
  function getPathDepth(path) {
    return path.split('/').filter(String).length;
  }

  if (
    typeof window.previousLocation !== 'undefined' &&
    getPathDepth(currentLocation.pathname) > 1
  )
    navigate(window.previousLocation.pathname);
  else navigate('/');
}

export default class PageHero extends Component {
  handleButtonBackClick = () => {
    navigateBackFrom(this?.props?.location);
  };

  render() {
    const { headline, backgroundImage } = this?.props?.pageHero[0];
    const bgImage = backgroundImage[0]
      ? `url(' ${backgroundImage[0].hero} ')`
      : 'none';

    return (
      <HeroSection
        backgroundImage={bgImage}
        className={bgGradientBlueOverlay50}
      >
        <Container>
          <ButtonBackStyled onClick={this.handleButtonBackClick} />
          <HeroContent>
            {headline && <Headline tag="h1">{headline}</Headline>}
          </HeroContent>
        </Container>
      </HeroSection>
    );
  }
}

export const query = graphql`
  fragment PageHeroQuery on Craft_PageHeroHero {
    headline
    backgroundImage {
      url
      hero: url(transform: transformHero)
    }
  }
`;
