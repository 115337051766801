import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Moment from 'react-moment';

import styled from 'react-emotion';

import { parseAsText } from 'utils/parser';
import media from 'utils/mediaqueries';

import PageHero from 'components/PageHero';
import Seo from 'components/Seo';
import Container from 'components/Container';
import Section from 'components/Section';
import Headline from 'components/Headline';
import TextDefault from 'components/Text';

const SectionNews = styled(Section)({
  background: 'white',
});

const NewsHeader = styled('header')({
  textAlign: 'left',

  [media('md')]: {
    textAlign: 'center',
  },
});

const NewsDate = styled('p')(
  {
    marginBottom: 10,

    [media('md')]: {
      marginBottom: 20,
    },

    [media('lg')]: {
      marginBottom: 50,
    },
  },
  ({ theme }) => ({
    color: theme.primaryColor,
  }),
);

const NewsHeadline = styled(Headline)(
  {
    margin: '0 0 30px',

    [media('md')]: {
      padding: '0 10%',
      margin: '0 0 60px',
    },

    [media('lg')]: {
      padding: '0 12%',
    },
  },
  ({ theme }) => ({
    color: theme.primaryColor,
  }),
);

const NewsIntro = styled('p')({
  color: '#575757',
  lineHeight: '1.75',
  marginBottom: 40,

  [media('md')]: {
    padding: '0 10%',
  },
});

const NewsContent = styled('div')({
  p: {
    marginBottom: 40,
  },
});

export default class News extends Component {
  render() {
    const {
      data: {
        craft: { newsEntry, newsHero },
      },
    } = this.props;
    const { title, newsIntro, newsContent, postDate } = newsEntry;

    return (
      <>
        <Seo
          title={newsEntry.seoTitle || newsEntry.title}
          description={newsEntry.seoDescription}
        />
        {newsHero?.pageHero.length > 0 && (
          <PageHero
            pageHero={newsHero.pageHero}
            location={this.props.location}
          />
        )}
        <SectionNews>
          <Container>
            <NewsHeader>
              {postDate && (
                <NewsDate>
                  <Moment format="DD.MM.YYYY" unix>
                    {postDate}
                  </Moment>
                </NewsDate>
              )}
              {title && (
                <NewsHeadline tag="h2">{parseAsText(title)}</NewsHeadline>
              )}
              {newsIntro && <NewsIntro>{newsIntro}</NewsIntro>}
            </NewsHeader>
            {newsContent && (
              <NewsContent>
                <TextDefault tag="div" className="text">
                  <div
                    dangerouslySetInnerHTML={{ __html: newsContent.content }}
                  />
                </TextDefault>
              </NewsContent>
            )}
          </Container>
        </SectionNews>
      </>
    );
  }
}
// dateCreated @date(as: "F j, Y")
export const newsQuery = graphql`
  query NewsQueryById($id: [Int]!) {
    craft {
      newsHero: entry(slug: "aktuelles") {
        ... on Craft_Pages {
          seoTitle
          seoDescription
          pageHero {
            ...PageHeroQuery
          }
        }
      }
      newsEntry: entry(id: $id) {
        ... on Craft_News {
          seoTitle
          seoDescription
          id
          title
          postDate
          newsIntro
          newsContent {
            content
          }
        }
      }
    }
  }
`;
